import {
    HOMEPAGE_SEASONAL_SPD,
    HOMEPAGE_SEASONAL_VALENTINE,
} from '../pages/home/constants/analytics';

export interface SEASONAL_MODULE {
    [key: string]: {
        title: string;
        subTitle: string;
        description: string;
        viewMoreUrl: string;
        buckets: string[];
    };
}

export const SEASONAL_FEATURED_MODULE: SEASONAL_MODULE = {
    VALENTINE: {
        title: "Valentine's day your way",
        subTitle: '',
        description: '',
        viewMoreUrl: '/b/local/seasonal/?utm_source=valentines-featured',
        buckets: ['All', 'Music', 'Date Night', 'Hobbies'],
    },
    SPD: {
        title: "St. Patrick's day your way \u{1F340}",
        subTitle: '',
        description: '',
        viewMoreUrl: '',
        buckets: ['Bar Crawl', 'Parties', 'Festive Fun'],
    },
};

export const STATSIG_EVENT_FOR_SEASONAL_MODULE_MAPPING: {
    [key: string]: string;
} = {
    VALENTINE: HOMEPAGE_SEASONAL_VALENTINE,
    SPD: HOMEPAGE_SEASONAL_SPD,
};
