import { TLD_GLOBALLY } from '../../../../constants/constants';
import { TopDestinations } from '../../../../types';
import {
    topDestination,
    TOP_DESTINATIONS,
    TOP_DESTINATIONS_DOMAIN,
} from './TopDestinationsData';

const getCurrentTld = (ebDomain: string) => {
    const ebDomainParts = ebDomain?.split('.') || [];
    return `.${ebDomainParts.splice(1).join('.')}`;
};

const getCloudfrontPrefix = (ebDomain: string) => {
    const cloudfrontPrefixes: Readonly<Record<string, string>> = Object.freeze({
        evbdev: 'd1eztp617dyzm5',
        evbqa: 'd3ajtuaqnvf0h0',
        evbstage: 'd23quzpik3ova8',
        eventbrite: 'd1n9ior3u0lhlo',
    });

    // ebDomain looks like evbdev.com / eventbrite.de / etc
    const ebDomainParts = ebDomain?.split('.') || [];
    const site = ebDomainParts[0];
    return cloudfrontPrefixes[site] || cloudfrontPrefixes.eventbrite;
};

export const useTopDestinations = (
    ebDomain: string,
    localizedCountryName: string,
    topDestinations?: TopDestinations,
): [string | null, topDestination[] | []] => {
    const tld = getCurrentTld(ebDomain);
    const topDestinationsStaticData = TOP_DESTINATIONS[tld]
        ? TOP_DESTINATIONS[tld]
        : TOP_DESTINATIONS[TLD_GLOBALLY];
    const topDestinationsData =
        topDestinations && topDestinations.length > 0
            ? topDestinations
            : topDestinationsStaticData;

    const countryDisplayName = TOP_DESTINATIONS_DOMAIN.includes(tld)
        ? localizedCountryName
        : null;

    const cloudfrontPrefix = getCloudfrontPrefix(ebDomain);
    const topDestinationsWithImage =
        topDestinationsData?.map(
            (top_destination) =>
                ({
                    displayName: top_destination.display_name,
                    locationSlug: top_destination.location_slug,
                    // S3 saves the image file names with underscores instead of percent signs.
                    imageUrl:
                        `https://${cloudfrontPrefix}.cloudfront.net/${top_destination.location_slug}.webp`.replaceAll(
                            '%',
                            '_',
                        ),
                } as topDestination),
        ) || [];

    return [countryDisplayName, topDestinationsWithImage];
};
