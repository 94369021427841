import { InlineAlert } from '@eventbrite/marmalade';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import {
    AVAILABLE_REFERRAL_ALERTS,
    FAILURE_ALREADY_CONSUMED_STATUS,
    FAILURE_CONNECTION_ISSUE_STATUS,
    FAILURE_OWN_REFERRAL_CODE_STATUS,
    REFERRAL_CREDIT_MSG,
    REFERRAL_SESSION_KEY,
    SUCCESS_STATUS,
} from '../../constants/referral';
import './ReferralCreditMessage.scss';

const getAlertData = (
    referralResponse: any,
): REFERRAL_CREDIT_MSG | undefined => {
    if (referralResponse) {
        return handleValidReferral(referralResponse);
    }
    return undefined;
};

const handleValidReferral = (referralResponse: any) => {
    let alert_status = FAILURE_CONNECTION_ISSUE_STATUS;

    if (referralResponse.result === SUCCESS_STATUS) {
        alert_status = SUCCESS_STATUS;
    } else {
        switch (referralResponse.code) {
            case FAILURE_ALREADY_CONSUMED_STATUS:
                alert_status = FAILURE_ALREADY_CONSUMED_STATUS;
                break;
            case FAILURE_OWN_REFERRAL_CODE_STATUS:
                alert_status = FAILURE_OWN_REFERRAL_CODE_STATUS;
                break;
        }
    }
    return AVAILABLE_REFERRAL_ALERTS[alert_status];
};

export const ReferralCreditMessage: React.FunctionComponent = () => {
    const [referralAlertData, setReferralAlertData] =
        useState<REFERRAL_CREDIT_MSG>();

    useEffect(() => {
        handleAlertDataAssign();
    }, []);

    const handleAlertDataAssign = () => {
        try {
            let referralResponse: any =
                sessionStorage.getItem(REFERRAL_SESSION_KEY);

            referralResponse = referralResponse
                ? JSON.parse(referralResponse)
                : undefined;

            setReferralAlertData(getAlertData(referralResponse));
            sessionStorage.removeItem(REFERRAL_SESSION_KEY);
        } catch (err) {
            Sentry.captureException(
                Error(
                    'Failed to read the sessionStorage property from Window: Access is denied for this document.',
                ),
            );
        }
    };

    const closeOnClick = () => {
        setReferralAlertData(undefined);
    };

    return (
        <>
            {referralAlertData && (
                <InlineAlert
                    closeOnClick={closeOnClick}
                    className="referralCreditMsg"
                    closeTitle="Close"
                    description={alertDescription(
                        referralAlertData.description,
                    )}
                    icon={referralAlertData.icon}
                    title={referralAlertData.title}
                    variant={referralAlertData.variant}
                />
            )}
        </>
    );
};

const alertDescription = (description: any) => {
    return (
        <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: description.toString(),
            }}
        />
    );
};
