import { VerticalEventCard } from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';
import './DiscoverBucketCard.scss';
interface DiscoverBucketCardProps {
    statsigEventName: string;
    heapId: string;
    bucketUrl: string;
    imgSrc: string;
    title: string;
    description?: string;
}
const STATIC_IMAGE_HEIGHT = 285;
const STATIC_IMAGE_WIDTH = 285;
export const DiscoverBucketCard: React.FunctionComponent<
    DiscoverBucketCardProps
> = (props) => {
    const { statsigEventName, heapId, bucketUrl, imgSrc, title, description } =
        props;
    const handleOnClick = () => {
        logEvent(statsigEventName);
    };
    return (
        <section
            className="discover-bucket-card"
            data-testid="discover-bucket-cards"
        >
            <VerticalEventCard actionVisibility="hidden">
                <a
                    href={bucketUrl}
                    onClick={handleOnClick}
                    target="_blank"
                    rel="noreferrer"
                    data-heap-id={heapId}
                >
                    <div className="event-card-image__aspect-container">
                        <img
                            height={STATIC_IMAGE_HEIGHT}
                            width={STATIC_IMAGE_WIDTH}
                            src={imgSrc}
                            className="event-card-image"
                            key={imgSrc}
                            alt={description || title}
                        />
                        <span className="bucket-card-title">
                            <Typography variant="body-lg">
                                {gettext('%(title)s ', { title })}
                            </Typography>
                        </span>
                    </div>
                </a>
            </VerticalEventCard>
        </section>
    );
};
