import { SimpleCarousel } from '@eventbrite/collection-carousel';
import { track } from '@eventbrite/datalayer-library';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DiscoverBucketCard } from '../../../../components/DiscoverEventCard/DiscoverBucketCard';
import {
    SEASONAL_FEATURED_MODULE,
    STATSIG_EVENT_FOR_SEASONAL_MODULE_MAPPING,
} from '../../../../constants/seasonalModule';
import { ConnectedProps, DiscoverState } from '../../../../types';
import { SEASONAL_MODULE_DATA } from '../../constants/category';
import './SeasonalModule.scss';

export interface SeasonalModuleProps extends ReduxProps {
    seasonal: string;
}
interface TitleSection {
    seasonal: string;
}

const TitleDescription = React.memo(({ seasonal }: TitleSection) => (
    <div className="title-container">
        <div className="title-subtitle-container">
            <h1 className="eds-text-hs">
                {SEASONAL_FEATURED_MODULE[seasonal].title}
            </h1>
        </div>
    </div>
));

export const SeasonalModule = ({
    location,
    seasonal,
    isMobile,
}: SeasonalModuleProps) => {
    const [locationSlug, setLocationSlug] = useState('local');

    React.useEffect(() => {
        if (location && location.currentPlace) {
            track({
                eventName: 'HomepageSeasonalModuleView',
                eventData: {
                    searchLocation: location.currentPlace,
                },
            });
        }
    }, [location]);

    React.useEffect(() => {
        if (location && location.slug) {
            setLocationSlug(location.slug);
        } else {
            setLocationSlug('local');
        }
    }, [location]);

    return (
        <div className="seasonal-module-buckets" data-testid="seasonal-module">
            <div className="seasonal-featured-container">
                <TitleDescription seasonal={seasonal} />
                <div className="simple-carousel-parent">
                    <SimpleCarousel slidesVisible={{ desktop: 4, tablet: 4 }}>
                        {SEASONAL_MODULE_DATA['SPD'].map((bucket) => (
                            <div
                                className="seasonal-featured-event-card"
                                key={bucket.id}
                            >
                                <DiscoverBucketCard
                                    statsigEventName={
                                        STATSIG_EVENT_FOR_SEASONAL_MODULE_MAPPING[
                                            seasonal
                                        ]
                                    }
                                    heapId={`homepage-module-bucket-${bucket.bucketUrl}`}
                                    bucketUrl={`b/${locationSlug}/seasonal/?bucket=${bucket.bucketUrl}`}
                                    imgSrc={
                                        isMobile
                                            ? bucket.imgSrcMobile
                                            : bucket.imgSrc
                                    }
                                    title={bucket.title}
                                    description={bucket.description}
                                />
                            </div>
                        ))}
                    </SimpleCarousel>
                </div>
            </div>
        </div>
    );
};

const _mapStateToProps = ({ app, user, location }: DiscoverState) => {
    return {
        isMobile: app.isMobile,
        isAuthenticated: user.isAuthenticated,
        location: location,
    };
};

type ReduxProps = ConnectedProps<typeof _mapStateToProps>;
export default connect(_mapStateToProps)(SeasonalModule);
