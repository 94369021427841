/* eslint-disable gettext/no-variable-string */
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { AlertOutlineIcon, CheckIcon } from '@eventbrite/marmalade';
import React from 'react';

const referralTermsLink =
    'https://www.eventbrite.com/help/en-us/articles/249260/';

export const REFERRAL_SESSION_KEY = 'referral_response';

export const SUCCESS_STATUS = 'success';
export const FAILURE_CONNECTION_ISSUE_STATUS = 'failure_connection_issue';
export const FAILURE_ALREADY_CONSUMED_STATUS = 'rewards_already_consumed';
export const FAILURE_OWN_REFERRAL_CODE_STATUS = 'own_referral_code';

export const SUCCESS_DESCRIPTION = `Now, let’s make some plans. You have 90 days to use your reward. View your rewards on the Rewards and Credits page in your profile. Review our <a href="${referralTermsLink}">Referral Terms</a> for more information.`;
export const FAILURE_CONNECTION_ISSUE_DESCRIPTION =
    'Try using the referral link again.';
const FAILURE_ALREADY_CONSUMED_DESCRIPTION =
    'Sorry, it looks like you’ve already received $10 from a friend.';
const FAILURE_OWN_REFERRAL_CODE_DESCRIPTION =
    'Looks like you selected your own link. Try sending it to some friends first.';

const SUCCESS_TITLE = "You've got $10 to spend on Eventbrite 🥳";
const FAILURE_CONNECTION_ISSUE_TITLE = 'There was a connection issue';
export const FAILURE_ALREADY_CONSUMED_TITLE = 'You already got a reward';
export const FAILURE_OWN_REFERRAL_CODE_TITLE = 'You can’t refer yourself';

export interface AVAILABLE_REFERRAL_ALERTS_TYPE {
    [key: string]: REFERRAL_CREDIT_MSG;
}

export interface REFERRAL_CREDIT_MSG {
    description: GenericLazyString;
    icon: any;
    title: GenericLazyString;
    variant:
        | 'success'
        | 'danger'
        | 'info'
        | 'warning'
        | 'opportunity'
        | undefined;
}

export const AVAILABLE_REFERRAL_ALERTS: AVAILABLE_REFERRAL_ALERTS_TYPE = {
    [SUCCESS_STATUS]: {
        description: gettext(SUCCESS_DESCRIPTION),
        icon: <CheckIcon />,
        title: gettext(SUCCESS_TITLE),
        variant: 'success',
    },
    [FAILURE_CONNECTION_ISSUE_STATUS]: {
        description: gettext(FAILURE_CONNECTION_ISSUE_DESCRIPTION),
        icon: <AlertOutlineIcon />,
        title: gettext(FAILURE_CONNECTION_ISSUE_TITLE),
        variant: 'danger',
    },
    [FAILURE_ALREADY_CONSUMED_STATUS]: {
        description: gettext(FAILURE_ALREADY_CONSUMED_DESCRIPTION),
        icon: <AlertOutlineIcon />,
        title: gettext(FAILURE_ALREADY_CONSUMED_TITLE),
        variant: 'danger',
    },
    [FAILURE_OWN_REFERRAL_CODE_STATUS]: {
        description: gettext(FAILURE_OWN_REFERRAL_CODE_DESCRIPTION),
        icon: <AlertOutlineIcon />,
        title: gettext(FAILURE_OWN_REFERRAL_CODE_TITLE),
        variant: 'danger',
    },
};
